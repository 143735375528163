body.front .view-songs {
	margin: 0;
	padding: 0;
	.view-content{
		display: none !important;
	}
}

.intro {
    padding-top: 1em;
    padding-bottom: 45px;
    color: rgba(255,255,255, .8);
    text-align: left;
  	&:after {
	    clear: both;
	    content: " ";
	    display: table;
	}
}

.cd-pisni {
	display: block;
	width: 60%;
	min-width: 320px;
	max-width: 400px;

  @media (min-width: $screen-xs-min) {
  	float: right;
  }

	position: relative;
	overflow: hidden;
	border-radius: 50%;	
	box-shadow: 0 .3em .7em rgba(0,0,0,.25);
	margin: 0 auto 15px;
	padding: 0;
	outline: 0;	

	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);

	&:before {
		content: ''; 
		position: absolute; 
		width: 100%; 
		height: 100%;
		z-index: 2;
		background: url(../images/shining.png) center no-repeat;
		background-size: cover;
		mix-blend-mode:  overlay;
		border-radius: 50%; //safari fox
		opacity: .6;
		transition: all 200ms ease-in-out;
	    cursor: pointer;
	}
	&:after{
		content: '';
		padding: 13%;
		background-color: rgba(5,5,5,.01);
		z-index: 10;
		position: absolute;
		top: 50%; 
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		box-shadow: inset 0 .2em .2em rgba(0,0,0,.5);
	}
	.disc {
		display: block;
		width: 100%; 
		height: 100%;
		border-radius: 50%;
		position: relative;

	  	&:before{
	  		content: '';
	  		padding: 5%;
	  		background-color: rgba(5,5,5,.1);
	  		z-index: 10;
	  		position: absolute;
	  		top: 50%; 
	  		left: 50%;
	  		transform: translate(-50%, -50%);
	  		border-radius: 50%;
	  		box-shadow: inset 0 .2em .2em rgba(0,0,0,.25);
	  	}	
	  	
	  	//animation-play-state: paused;
  	
    	@at-root #{selector-append(".playing", &)} {
			animation: spin 20s infinite linear;
			animation-delay: 200ms;
			//animation-play-state: running;
    	}

		img {
			display: block;
			width: 100%; 
			height: auto;
			position: relative;
			
		
		}

		.jp-info {
			position: absolute;
			width: 60%;			
			left: 20%;
			top: 68%;
			
			text-align: center;
			color: rgba(255,255,255, 1);
			text-shadow: 1px 1px 1px rgba(0,0,0,.5);
			font-weight: bold;
			opacity: .8;

		}
		.jp-title {
			font-size: 20px;
			line-height: 1.24;
		}
		.jp-artist {
			font-size: 14px;
			line-height: 1.3;
		}
	}
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}