
// Node
// --------------------------------------------------

 .node {
 	background: rgba(255,255,255,1) url(../images/bg_mask-sm.png) left top;
 	border-radius: 4px;
 	
 	

 	padding: 15px;

 	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;

 	> * {
 		order: 2;
 	}

 	h1.title {
 		margin: 0;
 		padding: 0 0 0px 0;
 		order: 0;

		font-size: 32px;
		line-height: 40px;
		font-family: 'Philosopher', sans-serif;
 	}

 	.node-rbpublished {
 		line-height: 30px;
 		order: 1;
 	}

 	.field-news-image {
 		order: 1;
 		margin-bottom: 1.5em;
 	}

 	.field-news-image img {
 		width: 100%;
 		object-fit: cover;
 	}

  .field-body img {
		max-width: 100% !important;
		height: auto !important;
	}

 
	// Photos -----------

	.field-photo-images {
		margin: 0 0 -15px 0;
		padding: 0 0 0 0;
		list-style: none;
		width: 100%;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;


		li {
			margin: 0 15px 15px 0;
			padding: 0;
			overflow: hidden;
			box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
			transition: box-shadow 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);

			width: 100%;
		  @media (min-width: $screen-xs-min) {
		  	width: calc(100% / 1 - 15px);
		  }
		  @media (min-width: $screen-sm-min) {
		  	width: calc(100% / 2 - 15px);
		  }
		  @media (min-width: $screen-md-min) {
		  	width: calc(100% / 3 - 15px);
		  }

			&:hover {
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			}

		}

		a {
			display: block;
			height: 100%;
		}

		img { 
			object-fit: cover;
			height: 100%;
			max-width: 100%;
			//transform: scale(1.1);
		}
	}


	// Lyrics -----------

	.field-lyrics-image img {
		width: 100%;
		object-fit: cover;
		height: auto;
	}

	.field-lyrics-artist {
 		order: 0;
		font-size: 24px;
		line-height: 30px;
		font-family: 'Philosopher', sans-serif;
		margin-bottom: 15px;
	}
	.field-lyrics-chords {
		font-size: 14px;
	    display: block;
        //font-family: Monaco, Menlo, Consolas, "Courier New", "Lucida Sans Typewriter", monospace;
	    white-space: pre;
	    word-break: break-all;
	    word-wrap: break-word;
	    margin-bottom: 15px;
	    // color: #000;
	}

	.field-label {
		font-size: 24px;
		line-height: 30px;
		font-family: 'Philosopher', sans-serif;	
	}

	// Song, Minus

	.field-minus-image img,
	.field-song-image img {
		width: 100%;
		object-fit: cover;
		height: auto;
	}

	.field-minus-artist, 
	.field-song-artist {
 		order: 0;
		font-size: 24px;
		line-height: 30px;
		font-family: 'Philosopher', sans-serif;
		margin-bottom: 15px;
	}

	.field-minus-mp3 *,
	.field-song-mp3 * {
		width: 100%;
	}

}

