
// Lyrics
// --------------------------------------------------

.view-lyrics {

	margin-bottom: 45px;

	.views-row {

		display: flex;
		flex-direction: row;
		position: relative;
		border-radius: 4px;

	    margin-bottom: 2px;
		padding-right: 0px;
		padding-left: 7px;

		background: rgba(255, 255, 255, 0.2);
	    transition: background 300ms ease-in-out;

	    line-height: 54px;
	    font-size: 14px;

	}

	.views-row:hover {
		background: rgba(255, 255, 255, 0.6);
	}

	.views-field {
		position: relative;
		margin-right: 15px;
	}

	.views-fieldset {
		display: flex;
		flex: 4;
	    overflow: hidden;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	    word-break: normal;
	    cursor: pointer;
	}

	.views-field-image,
	.views-field-counter,
	.views-fieldset {
		position: relative;
		cursor: pointer;
	}

	.views-field-image img {
		object-fit: cover;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin: 0;
		padding: 0;
	}

	.views-field-title,
	.views-field-artist {

	    color: #333;
	    font-weight: 400;

	    overflow: hidden;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	    word-break: normal;
	    cursor: pointer;

	}

	.views-field-title {
		font-weight: bold;

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	.views-field-artist {
		flex: 1;
		&:before {
			content: "-";
	    	margin: 0 1em 0 0;
		}
	}

	.views-field-path {
		display: none;
		a {
			color: #333;
			font-size: 18px;
		}		
	}

}
