// rbPlayer
// --------------------------------------------------
	

.rbplayer {

    height: 105px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
      
    background: url('../images/bg_mask-sm.png') #000 repeat-x bottom right;
    box-shadow: 0px -1em 3em rgba(25,25,25,.95);

	>:before {
	    height: 100%;
	    width: calc(100% + 80px);
	    position: absolute;
	    top: 0;
	    left: -80px;
	    box-shadow: 0px 10px 45px 0 rgba(0, 0, 0, 0.1);
	    z-index: 101;
	    pointer-events: none;
	}	

	.jp-interface {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start; //space-between;
		align-items: center;
		
	}

	.jp-toggles {
		display: none;
	}

	.jp-details {
		flex: 2;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		min-width: 100px;

		img.jp-poster {
			overflow: hidden;
	    display: block;
	    height: 105px;
	    margin-top: 5px;
	    margin-right: 16px;
	    position: relative;
	    z-index: 100;
			@media (max-width: $screen-sm-min) {
  	    margin-left: -15px;
			}
		}

		div.jp-info {
			display: flex;
	    flex-direction: column;
	    overflow: hidden;
			@media (max-width: $screen-xs-min) {
			  	display: none;
			}
		}

		.jp-title,
		.jp-artist { 
			font-family: 'Philosopher', sans-serif;
			font-weight: normal;
			color: rgba(255,255,255, .7);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;	

			display: block;			
      padding: 4px;
      margin: 0;
      width: 100%;
		}
		.jp-title { 
			font-size: 18px;
		}
		.jp-artist {
			font-size: 14px;
		}

	}

	.jp-controls {
		flex: 1;
		
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		button {
		    display: inline-block;
		    overflow: hidden;
		    text-indent: -9999px;
		    padding: 0;
		    border: none;
		    cursor: pointer;
		    outline: 0;
		    
		    width: 24px;
		    height: 24px;
		    margin: 22px;
		}

		.jp-play {
			width: 56px;
			height: 56px;
			margin: 8px;
	    border-radius: 50%;
	    background: #222 url(../images/play.svg) center/30px no-repeat;
	    transition: background-image 200ms ease-in-out;

	      @at-root #{selector-append(".jp-state-playing", &)} {
	        background-image: url(../images/pause.svg);	
	      }
		}	

		.jp-prev {
			background: url(../images/previous.svg) center/32px;
			@media (max-width: $screen-sm-min) {
			  	display: none;
			}
		}
		.jp-next {
			background: url(../images/next.svg) center/32px;
		}
		
		.jp-mute {
			background: url(../images/volume.svg) center/22px;  
		  border-radius: 22px;
		  margin: 30px;
	    opacity: .4;
			@media (max-width: $screen-sm-min) {
			  	display: none;
			}
			
      @at-root #{selector-append(".jp-state-muted", &)} {
        background-image: url(../images/volume-muted.svg);
  	    opacity: .8;
  	    animation: pulse 2s infinite;
  		  border-radius: 50%;
      }	
      @at-root #{selector-append(".jp-state-playing", &)} {
        
      }
	      
      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba(255,255,255,0.7);;
        }
        70% {
            box-shadow: 0 0 0 5px rgba(255,255,255, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        }
      }      
      
      	    
		}		
	}
	
	.jp-progress {
		
		width: 100%;
		position: absolute;
		top: 0px;
		right: 0px;
		opacity: .8;

		.jp-seek-bar {
			cursor: pointer;
			overflow: hidden;
			background-color: #555;
			z-index: 3333333;			
		}
		.jp-play-bar {
			height: 5px;
			background-color: rgba(255,255,255,0.7);
		}
	}
	
  .jp-volume {
    position: relative;

		@media (max-width: $screen-sm-min) {
		  	display: none;
		}

    .jp-volume-vertical {
      display: none;
    }
    &:hover .jp-volume-vertical {
      display: block;
      @at-root #{selector-append(".jp-state-muted", &)} {
        display: none;
      }
    }

    .jp-volume-bar {
        left: 28px;
        bottom: 58px;
        width: 28px;
        height: 118px;
        z-index: 1;
        position: absolute;
        background-color: #f2f2f2;
        border: 1px solid transparent;
        outline: 0;
        cursor: pointer;
        border-color: #ccc;
        box-shadow: 0 2px 4px rgba(0,0,0,.1);
        display: none;          
    }
    .jp-volume-bar:before,
    .jp-volume-bar:after {
        content: "";
        position: absolute;
        box-sizing: border-box;
        pointer-events: none;
    }
    .jp-volume-bar:after {
        transform-origin: 0 0;
        transform: rotate(-45deg);  
        bottom: -8px;
        left: 8px;
        z-index: 1;
        border: 4px solid #000;
        border-color: transparent transparent #f2f2f2 #f2f2f2;
        box-shadow: -3px 3px 4px rgba(0,0,0,.1);
    }
    .jp-volume-bar:before {
        position: absolute;
        bottom: 0;
        left: 12px;
        display: block;
        width: 2px;
        border: none;
        box-shadow: none;
        border-radius: 0;
        outline: 0;
        height: 95%;
        background-color: #ccc;  
    }
    .jp-volume-bar-value {
        z-index: 0;
        position: absolute;
        bottom: 0;
        left: 12px;
        display: block;
        width: 2px;
        border: none;
        box-shadow: none;
        border-radius: 0;
        outline: 0;
        background-color: #777;
    }
    .jp-volume-bar-value:after {
        content: "";
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #777;
        margin-left: -3px;
    }    
  }
}