.addthis_inline_share_toolbox {

	.at-share-btn-elements {
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		padding-top: 5px;	
		padding-bottom: 10px;
		margin-right: 30px;
	}

	a {
		width: 100%;
		white-space: nowrap;
		opacity: .8;
		&:hover {
			opacity: 1;
		}
	}

	&:last-child {
		padding-top: 15px;	
	}
	
}
