
// Photos
// --------------------------------------------------
 
.view-photo {

	.view-content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.views-row {

		margin-bottom: 30px;
		margin-right: 15px;

		width: 100%;
	  @media (min-width: $screen-xs-min) {
	  	width: calc(100% / 1 - 15px);
	  }
	  @media (min-width: $screen-sm-min) {
	  	width: calc(100% / 2 - 15px);
	  }
	  @media (min-width: $screen-md-min) {
	  	width: calc(100% / 2 - 15px);
	  }

		overflow: hidden;
		position: relative;
		
		background: rgba(255,255,255, 1);

		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
		transition: box-shadow 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);

		&:hover {
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		}
		&:hover .views-field-field-photo-image img {
			filter: contrast(100%);
			transform: scale(1.1);
		}

	}

	.views-field-field-photo-image {
		img { 
			object-fit: cover;
			width: 100%;
			max-height: 200px; // !

			filter: contrast(70%);
			transition: all 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
		}

		&:before,
		&:after {
		    position: absolute;
		    content: "\f03e";
		    font-family: FontAwesome;
		    z-index: 2;
		    line-height: 1em;
		    font-size: 30px;
		    top: 15px;
		    right: 15px;
		    color: rgba(255, 255, 255, 1);
		    text-shadow: 3px 3px 1px rgba(5, 5, 5, 0.2);
		}

	}

	.views-field-published {
		position: absolute;
		top: 15px;
		padding: 0 5px;
		color: #333;
		font-size: 12px;
		opacity: .8;
		background: rgba(255,255,255,1);
		z-index: 1;
	}

	.views-field-title {
		order: 3;
		z-index: 2;
		bottom: 0;
		width: 100%;
		height: 100%;
		min-height: 30px;
		
		background: url('../images/bg_mask-sm.png') #fafafa  no-repeat top left;
		
		a {
			color: #000;
			text-decoration: none;
			display: block;

			padding: 15px;

			font-size: 18px;
			line-height: 1.3;
			font-weight: 400;
			font-family: 'Philosopher', sans-serif;

			&:after{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: " ";
			}
		}

	}

}