
// Video
// --------------------------------------------------
 
.view-video {

	.view-content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		justify-content: flex-start;

	//	padding-left: 15px;
	//	padding-right: 15px;
	}

	.views-row {

		margin-bottom: 30px;
		margin-right: 15px;

		// max-width: 320px;
		width: 100%;

	  @media (min-width: $screen-xs-min) {
	  	width: calc(100% / 2 - 15px);
	  	//max-width: 320px;
	  }
	  @media (min-width: $screen-sm-min) {
	  	width: calc(100% / 2 - 15px);
	  }
	  @media (min-width: $screen-md-min) {
	  	width: calc(100% / 2 - 15px);
	  }

		overflow: hidden;
		position: relative;
		
		background: rgba(255,255,255, 1);

		display: flex;
		flex-direction: column;

		box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
		transition: box-shadow 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);

		&:hover {
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		}
		&:hover .views-field-field-video-youtube img {
			filter: contrast(100%);
			transform: scale(1.1);
		}

	}


	.views-field-field-video-youtube {
		position: relative;
		margin-top: -9.5%;
		// 480x360 ??? 16:9
		
		img { 
			object-fit: scale-down;
			width: 100%;

			filter: contrast(70%);
			transition: all 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);

			margin-bottom: -24%;
			overflow: hidden;

		}

		&:before {
		    position: absolute;
		    content: "\f144";
		    font-family: FontAwesome;
		    z-index: 2;
		    line-height: 1em;
		    color: rgba(255, 255, 255, 1);
		    font-size: 60px;
		    top: 45px;
		    right: 15px;
		}

	}

	.views-field-published {
		// position: absolute;
		color: #fff;
		font-size: 12px;
		opacity: .8;
	}
	.views-field-title,
	.views-field-published {
		padding: 15px;
	}

	.views-field-title {
		order: 3;
		z-index: 2;
		bottom: 0;
		width: 100%;
		height: 100%;
		min-height: 30px;
		background: url('../images/bg_mask-sm.png') #fafafa  no-repeat;
		
		a {
			color: #000;
			text-decoration: none;
			display: block;

			font-size: 18px;
			line-height: 1.3;
			font-weight: 400;
			font-family: 'Philosopher', sans-serif;

			&:after{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: " ";
			}
		}

	}

}