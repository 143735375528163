
// Songs
// --------------------------------------------------

.view-songs,
.view-minus {

	margin-bottom: 45px;

	.views-row {

		display: flex;
		flex-direction: row;

	    margin-bottom: 2px;

		border-radius: 4px;

		background: rgba(255, 255, 255, 0.2);

		padding-right: 0px;
		padding-left: 7px;

	    position: relative;
	    line-height: 44px;

	    font-size: 14px;

        transition: all 300ms ease-in-out;
	}

	.views-row:hover {
		background: rgba(255, 255, 255, 0.6);
	}

	.views-row.playing {
		background: rgba(255, 255, 255, 0.5);
	}

	.views-field {
		// flex: 0 1 24%;
		position: relative;
		margin-right: 15px;
	}

	.views-fieldset {
		display: flex;
		flex: 4;
	    overflow: hidden;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	    word-break: normal;
	    cursor: pointer;
	}

	.views-field-image,
	.views-field-counter,
	.views-fieldset {
		position: relative;
		cursor: pointer;
	}

	.views-field-image img {
		object-fit: cover;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin: 0;
		padding: 0;
	}

	.views-row.playing.paused .views-field-image:before,
	.views-row.idle:hover .views-field-image:before {
	    position: absolute;
	    content: "\f144";
	    font-family: FontAwesome;
	    top: 2px;
	    left: 30%;
	    z-index: 3;
	    font-size: 18px;
	    color: #F3F3F1;
	}

	.views-row.playing .views-field-image:before {
	    position: absolute;
	    content: "\f28b";
	    font-family: FontAwesome;
	    top: 2px;
	    left: 30%;
	    z-index: 3;
	    font-size: 18px;
	    color: #F3F3F1;
	}	

	.views-field-counter {
	    opacity: 0.5;
	    font-size: 12px;
	}

	.views-field-title,
	.views-field-artist {

	    color: #333;
	    font-weight: 400;

	    overflow: hidden;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	    word-break: normal;
	    cursor: pointer;

	}

	.views-field-title {
		font-weight: bold;
	}

	.views-field-title a {
		color: inherit;
		text-decoration: none;
	}

	.views-field-artist:before {
		content: "-";
	    margin: 0 1em 0 0;
	}	

	.views-field-artist {
		flex: 1;
	}

	.views-field-path a,
	.views-field-mp3 a {
		color: #333;
		font-size: 18px;
	}

}
