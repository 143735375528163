
// Navbars
// --------------------------------------------------

.navbar-default {
	background-color: transparent;
	border: 0;
	border-color: transparent;
	height: auto;
  margin-top: 40px;
  margin-bottom: 30px;
}

.navbar-default .navbar-toggle {
  background-color: transparent;
  &:focus,
  &:hover {
    background-color: rgba(255,255,255,0.1);
  }
}

  
.navbar-default .navbar-collapse {
  @media (max-width: $screen-sm-min) {
    border: 0;
    // background-color: rgba(255,255,255,0.1);
  }
}

.navbar-brand {
    float: left;
    padding: 0;
    font-size: 18px;
    line-height: 20px;
    margin-right: 20px;
  @media (min-width: $screen-sm-min) {
  	height: $logo-sm-height;
  }
  @media (min-width: $screen-md-min) {
  	height: $logo-md-height;
  }
  @media (min-width: $screen-lg-min) {
  	height: $logo-lg-height;
  }

}
.navbar-brand span {
	display: none;
}

.navbar-brand>img {
  position: relative;
  opacity: 0.8;
	height: $logo-sm-height;

  @media (min-width: $screen-sm-min) {
  	height: $logo-sm-height;
  }
  @media (min-width: $screen-md-min) {
  	height: $logo-md-height;
  }
  @media (min-width: $screen-lg-min) {
  	height: $logo-lg-height;
  }
}

.navbar-default .navbar-nav>li {
  @media (max-width: $screen-sm-min) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    &:last-child {
      border-width: 3px;
    }
  }
}

.navbar-default .navbar-nav>li>a {
	background: rgba(255, 255, 255, 0);
	padding: 10px 7px;
	@media (min-width: $screen-sm-min) {
	}
	@media (min-width: $screen-md-min) {
		padding: 15px 10px;
	}
	@media (min-width: $screen-lg-min) {
		padding: 20px 15px;
	}
}
.navbar-default .navbar-nav>li>a {
  width: 100%;
	font-family: 'Philosopher', sans-serif;
	color: rgba(255, 255, 255, 1);
  font-size: 20px;
	@media (min-width: $screen-sm-min) {
    font-size: 15px;
	}
	@media (min-width: $screen-md-min) {
		font-size: 18px;
	}
	@media (min-width: $screen-lg-min) {
		font-size: 20px;
	}
}

.navbar-default .navbar-nav>li>a:hover, 
.navbar-default .navbar-nav>li>a:focus {
	color: rgba(255, 255, 255, 1);
}

.navbar-default .navbar-nav>.active>a, 
.navbar-default .navbar-nav>.active>a:hover, 
.navbar-default .navbar-nav>.active>a:focus {
	color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
}


.navbar-default .navbar-nav>li>a {
  color: rgba(255, 255, 255, 1);
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
}
.navbar-default .navbar-nav>li>a:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  height: 4px;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.navbar-default .navbar-nav>li:hover>a:before, 
.navbar-default .navbar-nav>li:focus>a:before, 
.navbar-default .navbar-nav>li:active>a:before {
  color: rgba(255, 255, 255, 1);
  left: 0;
  right: 0;
  @media (max-width: $screen-sm-min) {
    left: auto;
    right: auto;
  }
}