
//
// Pager pagination
// --------------------------------------------------


.item-list .pager {

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	margin: 0;
	padding: 0 15px 0 0;
	list-style: none;

	.pager-current {
		max-width: 15px;
		overflow: hidden;
		text-indent: -9999px;
	}

	li {
		margin: 0;
		padding: 0;
		flex: 1;
	}

	a {

		width: 100%;
		display: inline-block;
	    vertical-align: middle;
	    overflow: hidden;
		background: url(../images/bg_mask-sm.png) #f0f0f0 no-repeat;
		
		font-family: 'Philosopher', sans-serif;
		line-height: 50px;	
		font-size: 32px;

		color: #000;
		text-decoration: none;
		font-weight: 400;
	    border-radius: 4px;
	    
		&:hover {
			border-color: rgba(255,255,255,.3);
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		}

	}
}