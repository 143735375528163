.editors-tabs {
	position: absolute;
	top: 20px;
	left: -15px;

	ul, li {
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
	}

	ul {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
	}

	li.active{
		display: none;
	}

	a {
		display: block;
		width: 48px;
		height: 48px;

		z-index: 100;
		position: relative;

		text-indent: -9999px;
		overflow: hidden;

		&:before {
			font: normal normal normal 30px/1 FontAwesome !important;

			color: black;
			content: "\f069";
			display: inline-block;

			speak: none;	
			text-transform: none;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			text-rendering: auto;

			text-indent: 0;

			position: absolute;
			top: 0;
			left:0;

		}
		&.edit:before  {
			content: "\f14b";
		}

		&.active{

		}
	}
}