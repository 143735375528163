// Page Layout
// -----------------------------------------------------------------------------

* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased!important;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

html, body{
	height: 100%;
	min-height: 100%;
}

// 750px max-width
// Uncaught (in promise) DOMException: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22

body > * {
	z-index: 1;
	position: relative;
}

.wrapper,
.wrapper:before {
	position: fixed;
	min-height: 100%; 
	width: 100%; 
	background-position: left bottom;
	background-repeat:  no-repeat;
	background-attachment: fixed;
	background-size:  cover;
}
.wrapper {
	opacity: 1;
	background-image: url(../images/bg_mask1600.png);
}
.wrapper:before {
	opacity: 0.7;
	background-image: url(../images/bg_sky1600.jpg) ;
	content: "";
}


.screen-reader-text, .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}


// float: right !important;

.page-title {
	position: absolute;
	display: none;
}

.main {
	min-height: calc(100% - 250px);
	padding-bottom: 120px;
}

.region.region-content {
  margin-bottom: 30px;
}
 

.footer {
	color: rgba(255,255,255,.8);
	padding: 0 15px;

	a {
		color: inherit;
		text-decoration: none;
	}
}

body.page-songs .footer,
body.page-minus .footer {
	display: none;
}

.widget {
    position: relative;
    display: block;
    margin-bottom: 30px;
}
.widget-title h3 {
	font-size: 18px;
	line-height: 30px;
	font-family: 'Philosopher', sans-serif;	
	color: rgba(255,255,255, .7);
	margin: 0;
	padding: 0;
}

.widget-social-counter {

  .widget-content-wrap {
    display: block;
    overflow: hidden;
    margin: -5px;
  }

	.counter-element {
    position: relative;
    display: block;
    float: left;
    padding: 5px;
    width: 33.33%;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0,0,0,.1);
    font-size: 14px;
	}

	.counter-element>a {
    outline: none;
    color: inherit;
    text-decoration: none;
    line-height: inherit;
    display: block;
    padding: 15px 5px;
    border-radius: 4px;
    text-align: center;
    transition: all .3s ease-in-out;
    opacity: .8;
	    &:hover {
	    	opacity: 1;
	    }   
	}

	.el-facebook a {
	    background-color: #899dce;
	    &:hover {
	    	background-color: #3a5795;
	    }
	}
	.el-instagram a {
	    background-color: #709cbf;
	    &:hover {
	    	background-color: #517fa4;
	    }
	}
	.el-youtube a {
	    background-color: #ef464b;
	    &:hover {
	    	background-color: #b31217;
	    }
	}
	.el-twitter a {
	    background-color: #60d2f5;
	    &:hover {
	    	background-color: #00a5e5;
	    }
	}

	.counter-element-inner {
		display: block;
	    text-transform: capitalize;
	    line-height: 22px;

		i {
		    display: block;
		    margin-right: 0;
		    vertical-align: middle;
		    font-size: 40px;
		    line-height: 40px;
		}
		.num-count {
		    display: none;
		    text-transform: uppercase;
		    letter-spacing: -.03em;
		    font-weight: 600;
		    font-size: 18px;
		    line-height: 24px;
		}
		.text-count {
		    display: none;
		    text-shadow: none;
		    font-size: 13px;
		    line-height: 19px;
		}
	}
}


.widget-contact-info {
  
  .widget-title h3 {
    cursor: pointer;
    color: rgba(255,255,255, .8);
    &:hover {
      color: rgba(255,255,255, 1);
    }
		&:after {
      display: inline-block;      
	    content: "\f05a";
	    font-family: FontAwesome;
	    font-size: smaller;
	    margin-left: 0.5em;
		}
  }
  
  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox]:checked ~ .contact-info-wrap {
    display: table;
    width: 260px;
  }  

  .contact-info-wrap {
      display: none;
      margin-top: -160px;
	    padding: 20px 30px;      

      background-color: rgba(255,255,255, 1);      
      box-shadow: 0 2px 5px 0 rgba(28,28,28,.1);
	    border-radius: 4px;
  }
  
	.contact-info-el {
	    display: block;
	    overflow: hidden;
	    margin-top: 4px;
	    color: #282828;
	    color: inherit;
	    line-height: 22px;
	}
	.contact-info-el i {
	    float: left;
	    margin-right: 10px;
	    color: inherit;
	    font-size: 15px;
	    line-height: 20px;
	}

	a {
	    outline: none;
	    color: inherit;
	    text-decoration: none;
	    line-height: inherit;
      &:hover {
        border-bottom: 2px dashed rgba(0,0,0,.5)
      }	
	}
	
}

