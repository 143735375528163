//
// --------------------------------------------------
 
.ckeditor-accordion-container > dl {
  border-color: #7d7d7d;
  dt {
    &.active > a {
      background-color: #7d7d7d;
    } 
    > a {
      background-color: #8f8f8f;
      border-bottom: 1px solid #858585;
      &:hover {
        background-color: #858585;        
      }
    } 
  }
  dd {
    padding: 15px;  
  } 
}
